
.App {
  text-align: center;
  background-color: #29516D;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f6e8d5;
}

a:link {
  color: #506F85;
}

a:visited {
  color: #CFA877;
}

a:hover {
  color: #AA7939;
}

a:active {
  color: #CFA877;
}


.Intro {
  font-size: calc(6px + 2vmin);
  width: 70%;
}

.Contact-Info {
  font-size: large;
}

.Publication-List {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: centerd;
  font-size: large;
  width: 70%;
}

.Publication {
  font-size: medium;
}
